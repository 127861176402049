import React, { Fragment, useEffect, useState } from "react";
import EditTodo from "./EditTodo";

const ListTodos = () => {
  const [todos, setTodos] = useState([]);

  const getTodos = async () => {
    try {
      const response = await fetch("https://todo.sanjayjr.com/api/todos", {
        method: "GET",
        headers: { "Content-Type": "application/json" },
      });
      const data = await response.json();
      setTodos(data);
    } catch (err) {
      console.error(err.message);
    }
  };

  const deleteTodo = async (id) => {
    try {
      await fetch(`https://todo.sanjayjr.com/api/todos/${id}`, {
        method: "DELETE",
      });
      setTodos(todos.filter((todo) => todo.todo_id !== id)); // Update state without reloading the page
    } catch (err) {
      console.error(err.message);
    }
  };

  const handleStatusChange = async (todo) => {
    const updatedStatus = todo.status === "Completed" ? "Pending" : "Completed";
    try {
      const response = await fetch(`https://todo.sanjayjr.com/api/todos/${todo.todo_id}`, {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ status: updatedStatus }),
      });
      if (response.ok) {
        setTodos(todos.map((item) =>
          item.todo_id === todo.todo_id ? { ...item, status: updatedStatus } : item
        ));
      }
    } catch (err) {
      console.error(err.message);
    }
  };

  useEffect(() => {
    getTodos();
  }, []);

  return (
    <Fragment>
      <table className="table mt-5 text-center">
        <thead>
          <tr>

          </tr>
        </thead>
        <tbody>
          {todos.map((todo, index) => (
            <tr key={todo.todo_id}>
              <td>{index + 1}</td>
              <td>
                <input
                  type="checkbox"
                  checked={todo.status === "Completed"}
                  onChange={() => handleStatusChange(todo)}
                />
              </td>
              <td>{todo.status}</td>
              <td>{todo.description}</td>
              <td><EditTodo todo={todo} /></td>
              <td>
                <button
                  className="btn btn-danger"
                  onClick={() => deleteTodo(todo.todo_id)}
                >
                  Delete
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </Fragment>
  );
};

export default ListTodos;
